<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contatti</li>
            </ul>
            <h2>Contattaci</h2>
        </div>
    </div>
    <div class="shape9"><img src="assets/img/shape8.svg" alt="image"></div>
</div>

<div id="map">
    <iframe src="https://maps.google.com/maps?q=Via%20Mattiuli%2021%2080047%20San%20Giuseppe%20Napoli%20Italia&t=k&z=19&ie=UTF8&iwloc=&output=embed"></iframe>
</div>

<div class="contact-area ptb-100">
    <div class="container">
        <div class="row mb-5">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title d-none">Chi siamo</span>
                    <h2>Il Migliore Ente di Formazione Accreditato e Certificato</h2>
                    <p>
                        Crediamo nella formazione, nel potenziamento e nel perfezionamento professionale come mezzo principale per modellare una società più competente e, di conseguenza, più efficiente.
                        Ci impegniamo ad offrire i mezzi e gli strumenti per armonizzare tutte le fasi dell’apprendimento attraverso professionisti in grado di sviluppare dispense semplificate o corsi online esplicativi e in
                        grado di preparare adeguatamente l’allievo.
                    </p>
                    <p>
                        Grazie al riconoscimento della Regione Campania, le certificazioni rilasciate dal Centro Studi FORMA rappresentano un requisito necessario ai fini lavorativi.
                        L’impegno prefissato è quello di preparare al mondo del lavoro, tutti gli iscritti ai corsi, attraverso una sempre più approfondita, offerta formativa e professionale
                    </p>
                    <p>
                        Promuoviamo la cultura dell’orientamento e della formazione attraverso studi di settore, report e ricerche puntuali, garantiamo assistenza informativa e aggiornamento costante sulle tendenze del mercato del lavoro, svolgiamo attività di potenziamento e certificazione delle competenze e delle conoscenze, nonché di supporto per la preparazione di esami universitari.
                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title d-none">dalla formazione alla professione</span>
                    <h2>Associazione Minerva</h2>
                    <p>
                        L’Associazione Minerva svolge attività di formazione certificazione professionale dal 2009 che è l’anno della sua costituzione. Abbiamo sempre creduto nella formazione, nel potenziamento e nel perfezionamento professionale come mezzo principale per modellare una società più competente e, di conseguenza, più efficiente.
                    </p>
                    <p>
                        L’offerta formativa e professionale dell’associazione ha sempre avuto un duplice obiettivo: preparare coloro i quali si affacciano per la prima volta al mondo del lavoro offrendo mezzi e strumenti sempre più innovativi e sempre aggiornati per armonizzare tutte le fasi dell’apprendimento, e aggiornare le competenze ed elaborare programmi di formazione per il personale delle aziende.
                    </p>
                    <p>
                        Il nostro fiore all’occhiello, oltre a una formazione polivalente, è costituito dal corpo docenti: professionisti di settore selezionati per i loro curricula e per la capacità di trasferire sapere ed esperienza, abili guide lungo il percorso di crescita degli studenti.
                    </p>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="contact-info">
                    <span class="sub-title">Informazioni</span>
                    <h2>Mettiti in contatto</h2>
                    <p>Ci piace ascoltare i lettori. Apprezziamo davvero che tu abbia dedicato del tempo per metterci in contatto.</p>
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-map'></i>
                            </div>
                            <h3>Dove siamo</h3>
                            <p>Via Mattiuli, 21<br>80047 San Giuseppe Vesuviano (NA)</p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-phone-call'></i>
                            </div>
                            <h3>Contatti</h3>
                            <p>Mobile: <a href="tel:+390815294590">(+39) 081-5294590</a></p>
                            <p>e-mail: <a href="mailto:info@centrostudiforma.it">info@centrostudiforma.it</a></p>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-time-five'></i>
                            </div>
                            <h3>Siamo disponibili</h3>
                            <p>Lunedì - Venerdì: 09:00 - 18:00</p>
                            <p>Sabato: 09:00 - 13:00</p>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="contact-form" *ngFor="let Image of bgImage;" style="background-image: url({{Image.img}});">
                    <h2>Come possiamo aiutarti?</h2>
                    <p>L'indirizzo email non verrà pubblicato. i campi richiesti sono contrassegnati dal simbolo*</p>
                    <form #contactForm="ngForm" (ngSubmit)="submit(contactForm.value)">
                        <div class="row">
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required minlength="3" maxlength="50" ngModel name="name" type="text" #name="ngModel" class="form-control" id="name" placeholder="Nome e Cognome">
                                    <div class="alert alert-danger" *ngIf="name.touched && !name.valid">
                                        <div *ngIf="name.errors.required">Nome e Cognome richiesto.</div>
                                        <div *ngIf="name.errors.minlength">Numero minimo {{ name.errors.minlength.requiredLength }} di caratteri.</div>
                                        <div *ngIf="name.errors.maxlength">Numero massimo di 50 caratteri.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-6">
                                <div class="form-group">
                                    <input required ngModel name="email" type="text" #email="ngModel" class="form-control" id="email" placeholder="la tua e-mail">
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <input required ngModel name="number" type="text" #number="ngModel" class="form-control" id="number" placeholder="cellulare">
                                    <div class="alert alert-danger" *ngIf="number.touched && !number.valid">un contatto telefonico è obbligatorio</div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea required ngModel #message="ngModel" name="message" id="message" cols="30" rows="5" class="form-control" placeholder="scrivi il tuo messaggio..."></textarea>
                                    <div class="alert alert-danger" *ngIf="message.touched && !name.valid">il messaggio è obbligatorio</div>
                                </div>
                            </div>
                            <h2 class="mt-3">Quale corso sei interessato?</h2>
                            <p>seleziona il corso interessato alla quale vuoi avere informazioni*</p>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <select name="sceltaCorso" ngModel id="sceltaCorso" #sceltaCorso="ngModel" style="display: block;
                                    width: 100%;
                                    border: none;
                                    color: var(--blackColor);
                                    background-color: #F3F6F9;
                                    height: 50px;
                                    padding-left: 15px;
                                    border-radius: 5px;
                                    font-size: var(--fontSize);
                                    font-weight: 600">
                                        <option value="{{elencoCorsiRow['corso']['titolo']}}" *ngFor="let elencoCorsiRow of elencoCorsi">{{elencoCorsiRow['corso']['titolo']}}</option>
                                      </select>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 mt-3">
                                <button type="submit" class="default-btn" [class.disabled]="!contactForm.valid">Invia Richiesta<span></span></button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
